import React, { useEffect, useState } from "react";

import Input from "components/shared/Inputs/Inputs";
import Button from "components/shared/Button/Button";
import { Col, Card, CardBody, Container, Row ,Label} from "reactstrap";
import Select from "react-select";

import api from "../../services/api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/Auth.service";
// import { Portal } from "@mui/material";

const EquipamentoKitCadastro = () => {
  let navigate = useNavigate();
  const initialState = {
    hydro: "",
    lora: "",
    location: "",
    region: "NORTE",
    latitude: "",
    longitude: "",
    locationType: "F",
    clock: "",
  };

  const regionArray = [
    { id: "NORTE", region: "Norte" },
    { id: "SUL", region: "Sul" },
    { id: "LESTE", region: "Leste" },
    { id: "OESTE", region: "Oeste" },
  ];
  const locationTypeArray = [
    { id: "F", locationType: "Final" },
    { id: "T", locationType: "Temporária" },
  ];

  const user = AuthService.getCurrentUser();



    let checkempresa = null;
    let DS_STATUS = null;
    if(user != null){
      
      DS_STATUS = user.user.DS_STATUS
    if(user.empresa[0] != undefined){
      checkempresa = user.empresa[0].OID_EMPRESA
    

    }
    }
    const Empresa = {
      OID_EMPRESA: "",
      NM_RAZAO_SOCIAL: "",
     
    };
  
    let stateempresa = null;

    let [lc, setLc] = useState (Empresa);
    const [search, setSearch] = useState(initialState);

  const [porta1, setPorta1] = useState(initialState);
  const [porta2, setPorta2] = useState(initialState);
  const [porta3, setPorta3] = useState(initialState);
  const [porta4, setPorta4] = useState(initialState);
  const [selectedLora, setSelectedLora] = useState(null);
  const [selectedCond, setSelectedCond] = useState(null);

  const [lorae, setLorae] = useState([]);

  let [makers, setMakers] = useState([]);
  const [condos, setCondo] = useState([]);
  const [emp, setEmp] = useState([]);

  const [lora, setLora] = useState([]);
  const [selectedMed01, setSelectedMed01] = useState(null);
  const [selectedMed02, setSelectedMed02] = useState(null);
  const [selectedMed03, setSelectedMed03] = useState(null);
  const [selectedMed04, setSelectedMed04] = useState(null);


  const [showDatalist, setShowDatalist] = useState(false);

  const fetchMakers = () => {
    let maker = [{  maker: "Selecione..." }];
    api.get("/imovel/hidro/"+user.user.OID_USUARIO).then((response) => {
      response.data.map((elem) => {
        maker.push({
          maker: elem.NR_MEDIDOR,
         
          
        });
      });
      setMakers(maker);
    });
  };

  const fetchHidroSindico = () => {
    let maker = [{ id: "",  maker: "Selecione..." }];
    api.get("/imovel/empresa/"+checkempresa).then((response) => {
      response.data.map((elem) => {
        maker.push({
          id: elem.OID_MEDIDOR,
          maker: elem.NR_MEDIDOR,
         
          
        });
      });
      setMakers(maker);
    });
  };

  const fetchLora = () => {
    let lora = [{ id: "", lora: "Selecione..." }];
    api.get("/equipmentslora/semregistro").then((response) => {
      response.data.map((elem) => {
        lora.push({
          value: elem.OID_EQUIPAMENTO_LORA,
          label: elem.NR_EQUIPAMENTO,
         
          
        });
      });
      setLora(lora);
    });
  };


  const fetchLoraEmpresa = () => {
    let lora = [{ id: "",  lora: "Selecione..." }];
    api.get("/equipmentslora/empresa/"+stateempresa).then((response) => {
      response.data.map((elem) => {
        lora.push({
          id: elem.OID_EQUIPAMENTO_LORA,
          lora: elem.NR_EQUIPAMENTO,
         
          
        });
      });
      setLora(lora);
    });
  };

  const fetchCondo = () => {
    let condo = [{ id: "",  condo: "Selecione..." }];
    api.get("/empresa/").then((response) => {
      response.data.map((elem) => {
        condo.push({
          value: elem.OID_EMPRESA,
          label: elem.NM_RAZAO_SOCIAL,
          
        });
      });
      setCondo(condo);
    });
  };
  

  useEffect(() => {
    fetchHidroSindico();
    fetchLora();
  }, []);

  if(DS_STATUS == 10){
    useEffect(() => {
      fetchCondo();
    }, []);
  }


  const onChangeHandlerloras = (selectedOption) => {
    setSelectedLora(selectedOption);
    console.log('Selected Lora:', selectedOption);
  };

  const onChangeHandlerCond = (selectedOption) => {
    setSelectedCond(selectedOption);

    setEmp(selectedOption.value)

    stateempresa = selectedOption.value;
   
    fetchHidroCondo();
    console.log('Selected Lora:', selectedOption.value);
  };




  const onChangeHandlerPorta1 = (prop) => (event) => {
    setPorta1({ ...search, [prop]: event.target.value });
  };
  const onChangeHandlerPorta2 = (prop) => (event) => {
    setPorta2({ ...search, [prop]: event.target.value });
  };
  const onChangeHandlerPorta3 = (prop) => (event) => {
    setPorta3({ ...search, [prop]: event.target.value });
  };
  const onChangeHandlerPorta4 = (prop) => (event) => {
    setPorta4({ ...search, [prop]: event.target.value });
  };


  const onChangeHandlerlora = (prop) => (event) => {
    setLorae({ ...search, [prop]: event.target.value });
  };

  const onChangeHandlerMed01 = (selectedOption) => {

    setSelectedMed01(selectedOption);
    
  };

  const onChangeHandlerMed02 = (selectedOption) => {

    setSelectedMed02(selectedOption);
  };


  const onChangeHandlerMed03 = (selectedOption) => {

    setSelectedMed03(selectedOption);
  };


  const onChangeHandlerMed04 = (selectedOption) => {

    setSelectedMed04(selectedOption);
  };


  

  const onChangeCondo = (prop) => (event) => {
    setLc({ ...lc, [prop]: event.target.value});
    setEmp(event.target.value)

    stateempresa = event.target.value;
   
      fetchHidroCondo();
    
      // fetchLoraEmpresa();
    
  };

  const fetchHidroCondo = () => {
    let maker = [{ id: "",  maker: "Selecione..." }];
    api.get("/imovel/empresa/semregistro/"+stateempresa).then((response) => {
      response.data.map((elem) => {
        maker.push({
          value: elem.OID_MEDIDOR,
          label: "Medidor: "+elem.NR_MEDIDOR+" - "+elem.imovel,
         
          
        });
      });
      setMakers(maker);
    });
  };


  const [register, setRegister] = useState(initialState);
  const [hydro, setHydro] = useState([]);
  

  const fetchHydro = () => {
    let hydro = [];
    api.get("/hydrometers").then((response) => {
      response.data.map((elem) => {
        hydro.push({
          id: elem.OID_HIDROMETRO,
          hydro: elem.NR_HIDROMETRO,
        });
      });
      setHydro(hydro);
    });
  };

  const onChangeHandler = (prop) => (event) => {
    setRegister({ ...register, [prop]: event.target.value });
  };

  const submitHandler = (event) => {
    event.preventDefault();
   

    let hydrometer = {};
    let equip = {};

    if (
      (!selectedMed01?.value || selectedMed01.value == 0) &&
      !selectedMed02?.value &&
      !selectedMed03?.value &&
      !selectedMed04?.value
    ) {
      Swal.fire({
        icon: "warning",
        text: "Selecione um Medidor",
      });
      return;
    }
    
    if (!selectedLora) {
      Swal.fire({
        icon: "warning",
        text: "Selecione um Modulo Lora",
      });
      return;
    }
    
    const medidores = [selectedMed01, selectedMed02, selectedMed03, selectedMed04];
    
    // Check for duplicates
    for (let i = 0; i < medidores.length; i++) {
      if (!medidores[i]?.value) continue; // Skip undefined or empty values
    
      for (let j = i + 1; j < medidores.length; j++) {
        if (medidores[j]?.value && medidores[i].value === medidores[j].value) {
          Swal.fire({
            icon: "warning",
            text: `Medidor Duplicado Porta ${i + 1}`,
          });
          return;
        }
      }
    }
    

    const registro = {
      
      OID_EQUIPAMENTO_LORA: selectedLora.value,
      DS_LOCAL_INSTALACAO: register.location,
      
    };

    api.post("/registros", registro).then((response) => {

      console.log(response)
      console.log(response.data.OID_REGISTRO)

      const medidores = [selectedMed01, selectedMed02, selectedMed03, selectedMed04];

      medidores.forEach((medidor, index) => {
        if (medidor?.value) {
          const portaData = {
            OID_REGISTRO: response.data.OID_REGISTRO,
            PORTA: (index + 1).toString(), // Convert index to 1-based string for "PORTA"
            OID_MEDIDOR: medidor.value,
          };
      
          api.post("/registrosportas/", portaData);
        }
      });

                const bodymod = {
                  
                  OID_EMPRESA: emp,
                };

                api.put(`/equipmentslora/${selectedLora.value}`, bodymod);
                console.log(bodymod)


      


      Swal.fire({
        icon: "success",
        title: "Kit cadastrado!",
      }).then(() => {
        navigate("/cadastro/equipamentokit")
      });
    }).catch((error) => {
      console.log(error);
      Swal.fire({
        icon: "warning",
        text: "Verifique os Dados e refaça o Cadastro!"
      })
    });
  };

  useEffect(() => {
    fetchHydro();
    fetchLora();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <label id="component-title">Cadastrar | Equipamento KIT</label>
        
        <div>
          <Button
            icon="bx bx-left-arrow-circle"
            iconPosition="left"
            primaryColor="#f8f7ff"
            hoverColor="#f8f7ff"
            text="Voltar"
            link="/cadastro/equipamentokit"
            styles={{
              marginLeft: "1.5rem",
              background: "#f8f7ff",
              color: "blue",
            }}
          />
        </div>
        <form className="component-form container" style={{ margin: "0px" }}>
          <div className="container">



               {DS_STATUS == 10?
                  <Row className="mb-3">
                    
                    <label
                      htmlFor="Condominio"
                      className="col-md-2 col-form-label"
                    >
                      Condomínio
                    </label>
                    <div className="col-6" style={{ textAlign: "start" }}>
                    {/* <Input
                      id="fab-search"
                      
                      options={[condos, "condo"]}
                      value={lc.condo}
                      type="select"
                       onChange={onChangeCondo("condo")}
                    
                      styles={{ width: "100%" }}
                    /> */}
                      <Select
                      // id="fab-search"
                      // className="form-control form-control-lg"
                      // label="Número Medidor"
                      // options={[makers, "maker"]}
                      // value={search.maker}
                      // type="select"
                      // onChange={onChangeHandler("nr")}
                      // styles={{ width: "100%" }}
                      label="Condominio"
                      id="inlineFormSelectPrefc"
                    placeholder="Selecione..."
                    onChange={onChangeHandlerCond}
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        minWidth: '245px',
                      }),
                  
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: 'white', // Set background color for the dropdown list
                        color: 'black', // Set text color for the dropdown list
                      })
                    }}
                    options={condos}
                    value={selectedCond}
                    />
                  </div>
                  </Row>
                    : null  }

                <Row className="mb-3">
                    
                    <label
                      htmlFor="Equipamento"
                      className="col-md-2 col-form-label"
                    >
                      Modulo Lora
                    </label>
                    <div className="col-6" style={{ textAlign: "start" }}>
                   
                     <Select
                    
                    id="inlineFormSelectPref"
                    placeholder="Selecione..."
                    onChange={onChangeHandlerloras}
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        minWidth: '245px',
                      }),
                  
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: 'white', // Set background color for the dropdown list
                        color: 'black', // Set text color for the dropdown list
                      })
                    }}
                    options={lora}
                    value={selectedLora}
                    />
                  </div>
                  </Row>




                    <Row className="mb-3">
                    
                    <label
                      htmlFor="Equipamento"
                      className="col-md-2 col-form-label"
                    >
                      Medidor Porta 01
                    </label>
                    <div className="col-6" style={{ textAlign: "start" }}>
                   
                     <Select
                    
                    id="inlineFormSelectPref"
                    placeholder="Selecione..."
                    onChange={onChangeHandlerMed01}
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        minWidth: '245px',
                      }),
                  
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: 'white', // Set background color for the dropdown list
                        color: 'black', // Set text color for the dropdown list
                      })
                    }}
                    options={makers}
                    value={selectedMed01}
                    isClearable
                    />
                  </div>
                  </Row>

    

                  <Row className="mb-3">
                    
                    <label
                      htmlFor="Equipamento"
                      className="col-md-2 col-form-label"
                    >
                      Medidor Porta 02
                    </label>
                    <div className="col-6" style={{ textAlign: "start" }}>
                   
                     <Select
                    
                    id="inlineFormSelectPref"
                    placeholder="Selecione..."
                    onChange={onChangeHandlerMed02}
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        minWidth: '245px',
                      }),
                  
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: 'white', // Set background color for the dropdown list
                        color: 'black', // Set text color for the dropdown list
                      })
                    }}
                    options={makers}
                    value={selectedMed02}
                    isClearable
                    />
                  </div>
                  </Row>


                  <Row className="mb-3">
                    
                    <label
                      htmlFor="Equipamento"
                      className="col-md-2 col-form-label"
                    >
                      Medidor Porta 03
                    </label>
                    <div className="col-6" style={{ textAlign: "start" }}>
                   
                     <Select
                    
                    id="inlineFormSelectPref"
                    placeholder="Selecione..."
                    onChange={onChangeHandlerMed03}
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        minWidth: '245px',
                      }),
                  
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: 'white', // Set background color for the dropdown list
                        color: 'black', // Set text color for the dropdown list
                      })
                    }}
                    options={makers}
                    value={selectedMed03}
                    isClearable
                    />
                  </div>
                  </Row>


                  <Row className="mb-3">
                    
                    <label
                      htmlFor="Equipamento"
                      className="col-md-2 col-form-label"
                    >
                      Medidor Porta 04
                    </label>
                    <div className="col-6" style={{ textAlign: "start" }}>
                   
                     <Select
                    
                    id="inlineFormSelectPref"
                    placeholder="Selecione..."
                    onChange={onChangeHandlerMed04}
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        minWidth: '245px',
                      }),
                  
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: 'white', // Set background color for the dropdown list
                        color: 'black', // Set text color for the dropdown list
                      })
                    }}
                    options={makers}
                    value={selectedMed04}
                    isClearable
                    />
                  </div>
                  </Row>

               


            {/* <div className="row">
              <div className="col-6">
                <Input
                  id="hydro"
                  label="Hidrômetro"
                  value={register.hydro}
                  onChange={onChangeHandler("hydro")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Input
                  id="lora"
                  label="Equipamento Lora"
                  value={register.lora}
                  onChange={onChangeHandler("lora")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div> */}
            {/* <div className="row mb-3">

            <label
                      htmlFor="Equipamento"
                      className="col-md-2 col-form-label"
                    >
                      Detalhes da Localização
                    </label>
              <div className="col-6">
                <Input
                  id="location"
                  
                  value={register.location}
                  onChange={onChangeHandler("location")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div> */}
            {/* <div className="row mb-3">
            <label
                      htmlFor="Equipamento"
                      className="col-md-2 col-form-label"
                    >
                      Região
                    </label>
              <div className="col-6">
                <Input
                  id="region"
                 
                  type="select"
                  options={[regionArray, "region"]}
                  value={register.region}
                  onChange={onChangeHandler("region")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="row mb-3">
            <label
                      htmlFor="Equipamento"
                      className="col-md-2 col-form-label"
                    >
                      Latitude
                    </label>
              <div className="col-6">
                <Input
                  id="latitude"
                  
                  value={register.latitude}
                  onChange={onChangeHandler("latitude")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div> */}
            {/* <div className="row mb-3">
            <label
                      htmlFor="Equipamento"
                      className="col-md-2 col-form-label"
                    >
                      Longitude
                    </label>
              <div className="col-6">
                <Input
                  id="longitude"
                
                  value={register.longitude}
                  onChange={onChangeHandler("longitude")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="row mb-3">
            <label
                      htmlFor="Equipamento"
                      className="col-md-2 col-form-label"
                    >
                      Tipo de Localização
                    </label>
              <div className="col-6">
                <Input
                  id="locationType"
                  
                  type="select"
                  options={[locationTypeArray, "locationType"]}
                  value={register.locationType}
                  onChange={onChangeHandler("locationType")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div> */}
            {/* <div className="row mb-3">
            <label
                      htmlFor="Equipamento"
                      className="col-md-2 col-form-label"
                    >
                      Relojoaria Inicial
                    </label>
              <div className="col-6">
                <Input
                  id="clock"
                  
                  value={register.clock}
                  onChange={onChangeHandler("clock")}
                  styles={{ width: "100%" }}
                />
              </div>
            </div> */}
            <div className="row">
              <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
                <Button
                  icon="ti-agenda"
                  text="Cadastrar"
                  action={submitHandler}
                  primaryColor="#5d4ec0"
                  hoverColor="#4eadc0"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default EquipamentoKitCadastro;
